<template>
  <div class="news">
    <div class="news-con">
      <div class="title">
        <h3>新闻资讯</h3>
        <div>
          当前位置：<router-link to="/Home">首页</router-link> >
          <span>新闻资讯</span>
        </div>
      </div>
      <div class="content">
        <div class="search">
          <input type="text" placeholder="请输入标题名称" v-model="search"  @keyup.enter="searchNews"/>
          <button @click="searchNews">搜索</button>
          <button @click="search = ''">清空</button>
        </div>
        <div class="new-box">
          <div class="information-title">
            <p>序号</p>
            <p>资讯标题</p>
            <p>发布时间</p>
          </div>
          <div class="noneNews" v-if="!loading && none">
            <img src="@/assets/images/news-none.png" alt="" />
            <p>暂时没有新闻资讯哦~~</p>
          </div>
          <div
            class="information-cont"
            v-if="!loading && !none"
            v-loading="loading2"
          >
            <ul>
              <li v-for="(list, i) in News" :key="list.newsCode">
                <p>{{ (pageNum - 1) * 10 + i + 1 }}</p>
                <p @click="goNewsDetails(list.newsCode)">
                  {{ list.newsTitle }}
                </p>
                <p>{{ list.updateTime }}</p>
              </li>
            </ul>
          </div>

          <el-skeleton
            style="width: 100%"
            :loading="loading"
            animated
            class="loading"
          >
          </el-skeleton>
          <el-pagination
            :page-size="10"
            :pager-count="9"
            layout="prev, pager, next"
            :total="total"
            @prev-click="prev"
            @next-click="next"
            @current-change="current"
            v-if="!none"
          >
          </el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "news-list",
  data() {
    return {
      loading: true,
      loading2: false,
      search: "",
      News: "",
      pageNum: 1,
      total: 0,
      none: false,
    };
  },
  created() {
    this.getNews();
  },
  methods: {
    //获取新闻列表
    async getNews() {
      const data = await this.$API.class.News({
        object: {
          conditions: this.search,
        },
        pageNum: this.pageNum,
        pageSize: 10,
      });
      this.News = data.data.data;
      console.log(this.News);
      this.total = data.data.total;
      if (this.News.length === 0) {
        this.none = true;
        this.loading = false;
      } else {
        this.none = false;
        this.loading = false;
      }
    },
    goNewsDetails(newsCode) {
      this.$router.push({
        name: "news-details",
        params: {
          newsCode: newsCode,
        },
      });
    },
    searchNews() {
      if (!this.loading2) {
        this.loading2 = true;
        setTimeout(() => {
          this.loading2 = false;
          this.getNews();
        }, 1000);
      }
    },
    //上一页
    prev() {
      this.pageNum--;
      this.getNews();
    },
    //下一页
    next() {
      this.pageNum++;
      this.getNews();
    },
    //分页数字
    current(i) {
      this.pageNum = i;
      this.getNews();
    },
  },
};
</script>

<style lang="scss" scoped>
.news {
  width: 100%;
  display: flex;
  justify-content: center;
  .news-con {
    width: 1200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    .title {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      height: 60px;
      margin-top: 16px;
      h3 {
        font-size: 24px;
        color: #333333;
      }
      div {
        font-size: 14px;
        color: #666;
        span {
          color: #00aeeb;
        }
        a {
          color: #333333;
        }
        a:hover {
          color: #00aeeb;
        }
      }
    }
    .content {
      width: 1200px;
      background: #fff;
      margin-bottom: 66px;
      .search {
        display: flex;
        height: 88px;
        align-items: center;
        input {
          width: 460px;
          height: 40px;
          background: #ffffff;
          border: 1px solid #dddddd;
          opacity: 1;
          border-radius: 2px;
          outline: none;
          text-indent: 12px;
          font-size: 14px;
          margin: 0 24px;
        }
        input::-webkit-input-placeholder {
          /* WebKit browsers */
          color: #dddddd;
        }

        input:-moz-placeholder {
          /* Mozilla Firefox 4 to 18 */
          color: #dddddd;
        }

        input::-moz-placeholder {
          /* Mozilla Firefox 19+ */
          color: #dddddd;
        }

        input::-ms-input-placeholder {
          /* Internet Explorer 10+ */
          color: #dddddd;
        }

        button {
          border: none;
          width: 76px;
          height: 40px;
          border: 1px solid #dddddd;
          border-radius: 2px;
          margin-right: 12px;
          cursor: pointer;
          font-size: 14px;
          color: #666666;
        }
        button:nth-child(2) {
          background: #00aeeb;
          border: none;
          color: #ffffff;
          border-radius: 2px;
        }
      }
      .information-title {
        width: 1200px;
        height: 42px;
        background: #eff2f7;
        display: flex;
        align-items: center;
        p {
          font-size: 16px;
          font-weight: bold;
          color: #333333;
        }
        p:nth-child(1) {
          width: 32px;
          margin: 0 60px 0 24px;
        }
        p:nth-child(2) {
          width: 866px;
        }
      }
      .noneNews {
        text-align: center;
        padding: 50px 0;
        p {
          height: 22px;
          font-size: 16px;
          font-weight: 400;
          line-height: 22px;
          color: #333333;
          opacity: 1;
          margin-top: 10px;
        }
      }
      .information-cont {
        width: 1200px;
        ul {
          li {
            display: flex;
            height: 52px;
            align-items: center;
            border-bottom: 1px solid #efefef;
            p {
              font-size: 16px;
              color: #666666;
            }
            p:nth-child(1) {
              width: 32px;
              margin: 0 60px 0 24px;
              text-align: center;
            }
            p:nth-child(2):hover {
              color: #00aeeb;
            }
            p:nth-child(2) {
              width: 866px;
              padding: 18px 0;
              cursor: pointer;
              padding-right: 24px;
            }
          }
        }
      }
      .el-pagination {
        display: flex;
        justify-content: center;
        margin: 30px 0 40px 0;
        ::v-deep button:hover {
          color: #00aeeb;
        }
        ::v-deep button {
          width: 34px;
          height: 34px;
          border: 1px solid #ddd;
          padding: 0;
          min-width: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          margin: 0 4px;
          i {
            margin-left: 1px;
          }
        }
        ::v-deep button:hover {
          color: #00aeeb;
        }
        ::v-deep .el-pager {
          li {
            width: 34px;
            height: 34px;
            padding: 0;
            border: 1px solid #ddd;
            min-width: 0;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #333333;
            line-height: 32px;
            margin: 0 4px;
          }
          li.active {
            color: #00aeeb;
            border-color: #00aeeb;
          }
          .more {
            border: none;
            margin: 0;
            width: 26px;
          }
        }
      }
      .new-box {
        .loading {
          // width: 100% ;
          padding: 18px;
        }
      }
    }
  }
}
</style>